'use strict';


(function ($) {

    $(document).ready(function () {

        // $(".fa-angle-up").click(function () {
        //     console.log(111);
        //     $('body').animate({scrollTop:0},600);
        // });
        $('.fa-angle-up').on('click', function(event) {
            var body = $("body, html");
            var top = body.scrollTop() // Get position of the body
            if(top!=0)
            {body.animate({scrollTop :0}, 600,function(){});}
        });

        $('.navbar-toggle').click(function (event) {
            $(".navbar-toggle").toggleClass("glyphicon-menu-hamburger glyphicon-remove");
            $(".mainmenu").toggleClass("active");
        });

        $('.section-slider').slick(
            {
                dots: false,
                arrows: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 4000,
                slidesToShow: 4,
                pauseOnFocus: false,
                pauseOnHover: false,
                responsive: [
                    {
                        breakpoint: 1174,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 970,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 750,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            }
        );

        $('.header .toggle-mobile i').click(function () {
            $('body').addClass('active-mobilemenu');
        });
        $('.mobilemenu .fa-close').click(function () {
            $('body').removeClass('active-mobilemenu');
        });


        $('.single-physiotherapie li.menu-item-25, .single-vacancies li.menu-item-21, .single-team li.menu-item-26').addClass('current-menu-item');

    });

    /* mainmenu: toggle fixed on scroll */
    var $header = $(".header");
    $(window).on("scroll load resize", function () {
        if ($(window).scrollTop() > 100 && $header.hasClass("default")) {
            $header.removeClass("default").addClass("fixed");
        } else if ($(window).scrollTop() <= 100 && $header.hasClass("fixed")) {
            $header.removeClass("fixed").addClass("default");
        }
        var hheight = $('.header').outerHeight();
        $('body').css('padding-top','0');
        $('body').css('padding-top',hheight);


        if ($(window).scrollTop() > 300 ) {
            $(".up").addClass("active");
        } else if ($(window).scrollTop() <= 300 ) {
            $(".up").removeClass("active");
        }

    });

    $(window).load(function () {
        $("body").addClass("loaded");
    });

    function matchHeight() {
        $(".matchHeight").removeAttr("style");
        var maxH = 0;
        $(".matchHeight").each(function () {
            var h_block = parseInt($(this).height());
            if (h_block > maxH) {
                maxH = h_block;
            }
        });
        $(".matchHeight").height(maxH);
    }

    $(window).on("load resize", function () {
        matchHeight();
    });

    $('.section-slider').viewportChecker({
        classToAdd: 'animated fadeInUp'
    });

})(jQuery);
