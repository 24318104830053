require("../sass/style.scss");


// require("./vendor/jquery/jquery-1.11.1.js");
require("./vendor/jquery/jquery-3.2.1.min.js");
require("./vendor/bootstrap.min.js");
require("./vendor/jquery.viewportchecker.js");
require("./vendor/lightbox.min.js");
require("./vendor/slick.min.js");


require("./custom/custom-global.js");